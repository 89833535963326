<template>
  <div>
    <title-bar
      submitBtn
      :submitBtnTitle="$t('partner.create')"
      :title="$t('partner.new')"
      @submitPressed="submitPressed"
    />
    <div class="partner-custom-form">
      <partner-form
        :partner-obj="partner"
        :custom-fields-obj="customFields"
        :action="action"
        @clearAction="action = null"
      />
    </div>
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import PartnerForm from "./components/PartnerForm.vue";

export default {
  components: {
    TitleBar,
    PartnerForm,
  },
  data() {
    return {
      action: null,
      partner: {
        givenName: "",
        familyName: "",
        companyName: "",
        additionalName: "",
        email: "",
        ico: "",
        dic: "",
        dphPayer: false,
        icdph: "",
        iban: "",
        swift: "",
        registeredAt: "",
        role: 'ROLE_PARTNER',
        address: {
          streetAddress: "",
          city: "",
          postalCode: "",
          country: ""
        },
        voucherDaysCount: 370
      },
      customFields: {
        'partner_contractUrl': {type: "STRING", value: ''},
        'partner_pricelistUrl': {type: "STRING", value: ''},
        'partner_emailMessage': {type: "STRING", value: ''},
        'partner_activationMessage': {type: "STRING", value: ''},
        'redeem_voucher': {type: "BOOLEAN", value: 'false'},
        'discounted_flights': {type: "BOOLEAN", value: 'false'},
        'record': {type: "BOOLEAN", value: 'false'},
        'invoices': {type: "BOOLEAN", value: 'false'}
      }
    };
  },
  methods: {
    submitPressed() {
      this.action = "add";
    },
  },
};
</script>
