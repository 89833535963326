<template>
  <b-form @submit.prevent="onSubmit">
    <b-card>
      <div class="row">
        <div class="col-md-6">
          <div
            :class="{ error: v$.partner.companyName.$errors.length }"
            class="form-group"
          >
            <label for="partner-companyName">
              <strong>{{ $t("partner.companyName") }}</strong>
            </label>
            <div>
              <b-form-input
                id="partner-companyName"
                v-model="partner.companyName"
                name="partner-companyName"
              />
              <div
                class="input-errors"
                v-for="error of v$.partner.companyName.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </div>
          </div>

          <div
            :class="{ error: v$.partner.ico.$errors.length }"
            class="form-group"
          >
            <label for="partner-ico">{{ $t("partner.ico") }}</label>
            <div>
              <b-form-input
                id="partner-ico"
                v-model="partner.ico"
                name="partner-ico"
              />
              <div
                class="input-errors"
                v-for="error of v$.partner.ico.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </div>
          </div>

          <div
            :class="{ error: v$.partner.dic.$errors.length }"
            class="form-group"
          >
            <label for="partner-dic">{{ $t("partner.dic") }}</label>
            <div>
              <b-form-input
                id="partner-dic"
                v-model="partner.dic"
                name="partner-dic"
              />
              <div
                class="input-errors"
                v-for="error of v$.partner.dic.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </div>
          </div>

          <div class="form-group">
            <b-form-checkbox v-model="partner.dphPayer">
              {{ $t("partner.dph") }}
            </b-form-checkbox>
          </div>

          <div v-if="partner.dphPayer" class="form-group">
            <b-form-checkbox v-model="partner.dphTransferredLiability">
              {{ $t("partner.dphTransferredLiability") }}
            </b-form-checkbox>
          </div>

          <div v-if="partner.dphPayer" class="form-group">
            <label for="partner-icdph">{{ $t("partner.icdph") }}</label>
            <b-form-input
              id="partner-icdph"
              v-model="partner.icdph"
              name="partner-icdph"
            />
          </div>

          <div
            :class="{ row, error: v$.partner.registeredAt.$errors.length }"
            class="form-group"
          >
            <label for="">{{ $t("partner.registeredAt") }}</label>
            <div>
              <b-form-textarea v-model="partner.registeredAt"/>
              <div
                class="input-errors"
                v-for="error of v$.partner.registeredAt.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </div>
          </div>

          <div
            :class="{ row, error: v$.partner.iban.$errors.length }"
            class="form-group"
          >
            <label for="">{{ $t("partner.iban") }}</label>
            <div>
              <b-form-input v-model="partner.iban"/>
              <div
                class="input-errors"
                v-for="error of v$.partner.iban.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </div>
          </div>

          <div
            :class="{ row, error: v$.partner.swift.$errors.length }"
            class="form-group"
          >
            <label for="">{{ $t("partner.swift") }}</label>
            <div>
              <b-form-input v-model="partner.swift"/>
              <div
                class="input-errors"
                v-for="error of v$.partner.swift.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </div>
          </div>

          <div
            :class="{
              error: v$.customFields.partner_contractUrl.value.$errors.length,
            }"
            class="form-group"
          >
            <label for="partner-contractUrl">{{
                $t("partner.contractUrl")
              }}</label>
            <div>
              <b-form-input
                id="partner-contractUrl"
                v-model="customFields.partner_contractUrl.value"
                name="partner-contractUrl"
              />
              <div
                class="input-errors"
                v-for="error of v$.customFields.partner_contractUrl.value
                  .$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </div>
          </div>

          <div
            :class="{
              error: v$.customFields.partner_pricelistUrl.value.$errors.length,
            }"
            class="form-group"
          >
            <label for="partner-pricelistUrl">{{
                $t("partner.pricelistUrl")
              }}</label>
            <div>
              <b-form-input
                id="partner-pricelistUrl"
                v-model="customFields.partner_pricelistUrl.value"
                name="partner-pricelistUrl"
              />
              <div
                class="input-errors"
                v-for="error of v$.customFields.partner_pricelistUrl.value
                  .$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </div>
          </div>

          <div
            :class="{ error: v$.partner.additionalName.$errors.length }"
            class="form-group"
          >
            <label for="partner-prefix">{{ $t("partner.prefix") }}</label>
            <div>
              <b-form-input
                id="partner-prefix"
                v-model="partner.additionalName"
                name="partner-prefix"
              />
              <div
                class="input-errors"
                v-for="error of v$.partner.additionalName.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </div>
          </div>

          <div
            :class="{ error: v$.partner.voucherDaysCount.$errors.length }"
            class="form-group"
          >
            <label for="partner-voucherDaysCount">
              {{ $t("partner.validityDays") }}
            </label>
            <div>
              <b-form-input
                id="partner-voucherDaysCount"
                v-model="partner.voucherDaysCount"
                name="partner-voucherDaysCount"
                type="number"
                step="1"
                min="1"
              />
              <div
                class="input-errors"
                v-for="error of v$.partner.voucherDaysCount.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </div>
          </div>

          <div class="form-group image-upload">
            <label for="partner-country">{{ $t("partner.signImage") }}</label>
            <div>
              <image-upload
                :preview-image="partner.fileUrl"
                label=""
                @fileUploaded="ilustrationImageChange"
                v-if="partner['@id']"
                :resource="partner['@id']"
                file-attribute="FILE"
              />
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div
            :class="{ error: v$.partner.givenName.$errors.length }"
            class="form-group"
          >
            <label for="partner-givenName">{{ $t("partner.givenName") }}</label>
            <div>
              <b-form-input
                id="partner-givenName"
                v-model="partner.givenName"
                name="partner-givenName"
              />
              <div
                class="input-errors"
                v-for="error of v$.partner.givenName.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </div>
          </div>

          <div
            :class="{ error: v$.partner.familyName.$errors.length }"
            class="form-group"
          >
            <label for="partner-familyName">{{
                $t("partner.familyName")
              }}</label>
            <div>
              <b-form-input
                id="partner-familyName"
                v-model="partner.familyName"
                name="partner-familyName"
              />
              <div
                class="input-errors"
                v-for="error of v$.partner.familyName.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </div>
          </div>

          <div
            :class="{ error: v$.partner.email.$errors.length }"
            class="form-group"
          >
            <label for="partner-email">{{ $t("partner.email") }}</label>
            <div>
              <b-form-input
                id="partner-email"
                v-model="partner.email"
                name="partner-email"
                @keydown.space.prevent
              />
              <div
                class="input-errors"
                v-for="error of v$.partner.email.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </div>
          </div>

          <div
            :class="{ error: v$.partner.plainPassword.$errors.length }"
            class="form-group"
          >
            <label for="partner-password">{{ $t("partner.password") }}</label>
            <div>
              <b-form-input
                id="partner-password"
                v-model="partner.plainPassword"
                name="partner-password"
              />
              <div
                class="input-errors"
                v-for="error of v$.partner.plainPassword.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </div>
          </div>

          <div
            :class="{ error: v$.partner.address.streetAddress.$errors.length }"
            class="form-group"
          >
            <label for="partner-streetAddress">{{
                $t("partner.streetAddress")
              }}</label>
            <div>
              <b-form-input
                id="partner-streetAddress"
                v-model="partner.address.streetAddress"
                name="partner-streetAddress"
              />
              <div
                class="input-errors"
                v-for="error of v$.partner.address.streetAddress.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </div>
          </div>

          <div
            :class="{ error: v$.partner.address.city.$errors.length }"
            class="form-group"
          >
            <label for="partner-city">{{ $t("partner.city") }}</label>
            <div>
              <b-form-input
                id="partner-city"
                v-model="partner.address.city"
                name="partner-city"
              />
              <div
                class="input-errors"
                v-for="error of v$.partner.address.city.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </div>
          </div>

          <div
            :class="{ error: v$.partner.address.postalCode.$errors.length }"
            class="form-group"
          >
            <label for="partner-postalCode">{{
                $t("partner.postalCode")
              }}</label>
            <div>
              <b-form-input
                id="partner-postalCode"
                v-model="partner.address.postalCode"
                name="partner-postalCode"
              />
              <div
                class="input-errors"
                v-for="error of v$.partner.address.postalCode.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </div>
          </div>

          <div
            :class="{ error: v$.partner.address.country.$errors.length }"
            class="form-group"
          >
            <label for="partner-country">{{ $t("partner.country") }}</label>
            <div>
              <b-form-input
                id="partner-country"
                v-model="partner.address.country"
                name="partner-country"
              />
              <div
                class="input-errors"
                v-for="error of v$.partner.address.country.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modules mb-3">
        <div class="module">
          <b-form-checkbox
            value="true"
            unchecked-value="false"
            v-model="customFields.redeem_voucher.value"
          >{{ $t("voucher.redeem") }}
          </b-form-checkbox
          >
        </div>
        <div class="module">
          <b-form-checkbox
            value="true"
            unchecked-value="false"
            v-model="customFields.discounted_flights.value"
          >Zvýhodnené lety
          </b-form-checkbox
          >
        </div>
        <div class="module">
          <b-form-checkbox
            value="true"
            unchecked-value="false"
            v-model="customFields.record.value"
          >Spomienkový záznam
          </b-form-checkbox
          >
        </div>
        <div class="module">
          <b-form-checkbox
            value="true"
            unchecked-value="false"
            v-model="customFields.invoices.value"
          >Fakturácia a nastavenia
          </b-form-checkbox
          >
        </div>
      </div>

      <b-form-group>
        <label class="mb-2" for="">{{
            $t("partner.emailActivationMessage")
          }}</label>
        <tinymce
          :options="{ height: 200 }"
          :content="customFields.partner_emailMessage.value"
          @editorContentChanged="updateEmailMessage"
        />
      </b-form-group>

      <b-form-group class="mb-0">
        <label class="mb-2" for="">{{ $t("partner.activationMessage") }}</label>
        <tinymce
          :options="{ height: 200 }"
          :content="customFields.partner_activationMessage.value"
          @editorContentChanged="updateMessage"
        />
      </b-form-group>
    </b-card>
  </b-form>
</template>

<script>
import tinymce from "@/components/tinymce.vue";
import ImageUpload from "@/components/ImageUpload.vue";
import useVuelidate from "@vuelidate/core";
import { minLength} from "@vuelidate/validators";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
  components: {
    tinymce,
    ImageUpload
  },
  props: {
    partnerObj: {
      type: Object, default() {
      }
    },
    action: {type: String, default: null},
    customFieldsObj: {type: Array, default: () => []},
  },
  data() {
    return {
      partner: this.partnerObj,
      customFields: this.customFieldsObj,
    };
  },
  mixins: [ListUtils, ResourceUtils],
  created() {
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      partner: {
        givenName: {required: this.$translateError('required')},
        familyName: {required: this.$translateError('required')},
        companyName: {required: this.$translateError('required')},
        email: {required: this.$translateError('required'), email: this.$translateError('email')},
        ico: {required: this.$translateError('required')},
        dic: {required: this.$translateError('required')},
        iban: {required: this.$translateError('required')},
        swift: {required: this.$translateError('required')},
        registeredAt: {required: this.$translateError('required')},
        additionalName: {required: this.$translateError('required')},
        plainPassword: {minLength: minLength(8)},
        address: {
          streetAddress: {required: this.$translateError('required')},
          city: {required: this.$translateError('required')},
          postalCode: {required: this.$translateError('required')},
          country: {required: this.$translateError('required')},
        },
        voucherDaysCount: {required: this.$translateError('required')}
      },
      customFields: {
        partner_contractUrl: {
          value: {required: this.$translateError('required')},
        },
        partner_pricelistUrl: {
          value: {required: this.$translateError('required')},
        },
      },
    };
  },
  watch: {
    action() {
      this.onSubmit();
    },
  },
  methods: {
    ilustrationImageChange(data) {
      this.partner.file = data["@id"];
      this.partner.fileURL = data.url;
    },
    updateMessage(content) {
      this.customFields.partner_activationMessage.value = content;
    },
    updateEmailMessage(content) {
      this.customFields.partner_emailMessage.value = content;
    },
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        const body = {
          familyName: this.partner.familyName,
          givenName: this.partner.givenName,
          additionalName: this.partner.additionalName, // prefix
          email: this.partner.email,
          companyName: this.partner.companyName,
          ico: this.partner.ico,
          dic: this.partner.dic,
          icdph: this.partner.icdph,
          dphPayer: this.partner.dphPayer,
          dphTransferredLiability: this.partner.dphTransferredLiability,
          iban: this.partner.iban,
          swift: this.partner.swift,
          registeredAt: this.partner.registeredAt,
          role: this.partner.role,
          plainPassword: this.partner.plainPassword,
          enabled: true,
          address: {
            streetAddress: this.partner.address.streetAddress,
            city: this.partner.address.city,
            postalCode: this.partner.address.postalCode,
            country: this.partner.address.country,
          },
          voucherDaysCount: parseInt(this.partner.voucherDaysCount)
        };

        if (this.action) {
          if (this.action === "add") {
            this.create(
              this.$Users,
              body,
              this.$t("partner.created"),
              null,
              this.success,
              this.error
            );
          } else {
            this.update(
              this.$Users,
              this.$route.params.id,
              body,
              this.$t("partner.updated"),
              null,
              this.successUpdate,
              this.error
            );
          }
        }
      } else {
        this.$emit("clearAction");
      }
    },
    success(response) {
      for (const cfKey in this.customFields) {
        const cf = this.customFields[cfKey];
        cf.name = cfKey;
        cf.ownerResource = "USER";
        cf.ownerId = response.data.id;
        this.create(this.$CustomFields, cf);
      }
      this.$router.push("/partners");
    },
    successUpdate() {
      for (const cfKey in this.customFields) {
        const cf = this.customFields[cfKey];
        if (cf["@id"]) {
          this.update(this.$CustomFields, cf.id, cf);
        } else {
          cf.name = cfKey;
          cf.ownerResource = "USER";
          cf.ownerId = this.partner.id;
          this.create(this.$CustomFields, cf);
        }
      }
      this.$router.push("/partners");
    },
    error() {
      this.$emit("clearAction");
    },
  },
};
</script>
